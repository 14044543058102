import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { from } from "rxjs";
import { mergeMap, take } from "rxjs/operators";
import { State } from "../../../reducers";
import { RequestManagerService } from "../../../services/requestManager/request-manager.service";

@Injectable({
  providedIn: "root",
})
export class AdminCheckoutService {
  constructor(
    private requestManager: RequestManagerService,
    private store: Store<State>
  ) {}

  public getPaymentMethodsForLocation(locationId: number) {
    return from(
      this.requestManager.getAuth(
        "/r2o/paymentMethods?locationId=" + locationId
      )
    );
  }

  public payBookings(bookings, queryParams) {
    let bookingIds = _.map(bookings, "id");
    console.log(bookingIds);
    let params = new URLSearchParams(queryParams).toString();
    return this.requestManager
      .put("/payBookings?" + params, this.requestManager.getJWT(), bookingIds)
      
  }

  getPrinters(locationId: any) {
    return from(
      this.requestManager.getAuth(
        "/r2o/printers?locationId="+locationId
      )
    );
  }

  public getPayerUser(locationId:any) {
    return this.store
      .select((state) => state.auth.user)
      .pipe(
        take(1),
        mergeMap((user) => {
          return from(
            this.requestManager.getAuth(
              "receiptEmployees?where_condition=" +
                JSON.stringify([
                  {
                    model: "receiptEmployees",
                    field: "userId",
                    operator: "=",
                    value: user.data.user_id,
                    followingOperator:'AND'
                  },
                  {
                    model: "receiptEmployees",
                    field: "locationId",
                    operator: "=",
                    value: locationId,
                    
                  },
                ])
            )
          );
        })
      );
  }
}

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ConfigService } from '../../services/config/config.service';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { finalize, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { State } from '../../reducers';
import { faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-account-balance',
  templateUrl: "./account-balance.component.html",
  styleUrls: ["./account-balance.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AccountBalanceComponent
    implements OnInit, OnDestroy, OnChanges
{
  faCcVisa = faCcVisa;
  faCcmasterVard = faCcMastercard;
  @ViewChild("cardInfo") cardInfo: ElementRef;
  confirming = false;
  card: any;
  cardHandler = this.onChange.bind(this);
  cardError: string;
  secret: string;
  intent$: Observable<any>;
  payment_methods$: Observable<any[]>;
  selectedCard: any;
  user$: Observable<any>;
  amount = 0;
  saldo$: Observable<any>;
  voucherSaldo$: Observable<any>;
  refreshData: Subject<any>;
  loading = true;
  customerId = null;
  addCreditCardMode=false;
  sub;
  loadingPaymentMethods$= new BehaviorSubject(false);
  loadingfillAccount$= new BehaviorSubject(false);
  // mockPaymentMethodsData= [
  //     {
  //     "id": "pm_1Kt3M32eZvKYlo2CHF2OxolT",
  //     "object": "payment_method",
  //     "billing_details": {
  //         "address": {
  //             "city": null,
  //             "country": "BD",
  //             "line1": null,
  //             "line2": null,
  //             "postal_code": null,
  //             "state": null
  //         },
  //         "email": "jenny@example.com",
  //         "name": null,
  //         "phone": "+15555555555"
  //     },
  //     "card": {
  //         "brand": "visa",
  //         "checks": {
  //             "address_line1_check": null,
  //             "address_postal_code_check": null,
  //             "cvc_check": "pass"
  //         },
  //         "country": "US",
  //         "exp_month": 8,
  //         "exp_year": 2023,
  //         "fingerprint": "Xt5EWLLDS7FJjR1c",
  //         "funding": "credit",
  //         "generated_from": null,
  //         "last4": "4242",
  //         "networks": {
  //             "available": [
  //                 "visa"
  //             ],
  //             "preferred": null
  //         },
  //         "three_d_secure_usage": {
  //             "supported": true
  //         },
  //         "wallet": null
  //     },
  //     "created": 123456789,
  //     "customer": null,
  //     "livemode": false,
  //     "metadata": {
  //         "order_id": "123456789"
  //     },
  //     "type": "card"
  // },
  //     {
  //         "id": "pm_1Kt3M32eZvKYlo2CHF2OxolT",
  //         "object": "payment_method",
  //         "billing_details": {
  //             "address": {
  //                 "city": null,
  //                 "country": "BD",
  //                 "line1": null,
  //                 "line2": null,
  //                 "postal_code": null,
  //                 "state": null
  //             },
  //             "email": "jenny@example.com",
  //             "name": null,
  //             "phone": "+15555555555"
  //         },
  //         "card": {
  //             "brand": "visa",
  //             "checks": {
  //                 "address_line1_check": null,
  //                 "address_postal_code_check": null,
  //                 "cvc_check": "pass"
  //             },
  //             "country": "US",
  //             "exp_month": 8,
  //             "exp_year": 2023,
  //             "fingerprint": "Xt5EWLLDS7FJjR1c",
  //             "funding": "credit",
  //             "generated_from": null,
  //             "last4": "4242",
  //             "networks": {
  //                 "available": [
  //                     "visa"
  //                 ],
  //                 "preferred": null
  //             },
  //             "three_d_secure_usage": {
  //                 "supported": true
  //             },
  //             "wallet": null
  //         },
  //         "created": 123456789,
  //         "customer": null,
  //         "livemode": false,
  //         "metadata": {
  //             "order_id": "123456789"
  //         },
  //         "type": "card"
  //     }]

  amountControl = new FormControl("", [Validators.min(15),
      Validators.max(200)])
  constructor(
      private router: Router,
      private notifier: NotifierService,
      private config: ConfigService,
      private cd: ChangeDetectorRef,
      private rm: RequestManagerService,
      private store: Store,
      private translate: TranslateService,
      private matDialogService: MatDialog,
      private route: ActivatedRoute,
  ) {

      this.sub = this.route.params.subscribe(params => {

          if (params['id'] != undefined) {
              this.customerId= +params['id'];
              this.user$= from(this.rm.get('/user/' + this.customerId, this.rm.getJWT(), true));
          } else {
              this.customerId = this.rm.getIdOfUserThatIsLoggedIn();
              this.user$ = this.store.pipe(
                  select((state: State) => state.auth.user.data)
              );
          }
      });

  }

  ngOnInit(): void {

    this.refreshData = new BehaviorSubject(true);

      this.payment_methods$ = this.refreshData.asObservable().pipe(
          map(() => {

              this.loadingPaymentMethods$.next(true);
              return of();
          }),
          mergeMap(() => {
              // return of(this.mockPaymentMethodsData);
              return from(
                  this.rm.get("/stripe/availablePaymentMethods", this.rm.getJWT(), true)
              ).pipe(
                  map((result) => {

                      return result.data;
                  })
              );
          }),
          map((result) =>{
              this.loadingPaymentMethods$.next(false);
              return result;
          })
      );

    this.saldo$ = this.refreshData.asObservable().pipe(
      mergeMap(() => {
        return this.user$.pipe(
          take(1),
          mergeMap((user) => {
            return from(
              this.rm.get(
                "/user/" + user.user_id + "/accountsReceivable/saldo",
                this.rm.getJWT(),
                true
              )
            ).pipe(
              map((result) => {
                if (
                  Array.isArray(result.accountsReceivable) &&
                  result.accountsReceivable.length == 0
                ) {
                  return { amount: 0 };
                }

                return {amount: result.saldo};
              })
            );
          })
        );
      })
    );
    this.voucherSaldo$ = this.refreshData.asObservable().pipe(
      mergeMap(() => {
        return this.user$.pipe(
          take(1),
          mergeMap((user) => {
            return from(
              this.rm.get(
                "/user/" + user.user_id + "/voucher/saldo",
                this.rm.getJWT(),
                true
              )
            ).pipe(
              map((result) => {
                return {amount: result.saldo};
              })
            );
          })
        );
      })
    );





    this.intent$ = from(
      this.rm.get('/stripe/setupIntent', this.rm.getJWT(), true),
    ).pipe(
      map((result) => {
        this.secret = result.client_secret;
      })
    );
    this.intent$.subscribe();
    this.refreshData.next(true);
  }

  selectMethod(pm) {
    this.selectedCard = pm;
  }


  fillAccount() {
      this.loadingfillAccount$.next(true);
      if (this.amount < 20) {
          this.notifier.show({
              message: this.translate.instant('accountBalance.fillup'),
              type: 'error',
          });
          return;
      }


      this.loading = true;
      let result$ = this.rm
          .post(
              'voucher/topUpByPayment',
              { paymentMethodId: this.selectedCard.id, amount: this.amount },
              this.rm.getJWT(),
          )
          .pipe(
              mergeMap((result: any) => {
                  if (result.success === true) {
                      this.notifier.show({
              message: this.translate.instant("accountBalance.success"),
              type: "success",
            });
            this.refreshData.next(true);
            return of(true);
          } else if (
            result.success === false &&
            result.details == "authentication_required"
          ) {
            return this.showPaymentIntentLater(
              result.clientSecret,
              result.paymentMethodId
            ).pipe(
              mergeMap(() => {
                return from(
                  this.rm.get(
                    "/stripe/finishPaymentIntent/" + result.paymentIntentId,
                    this.rm.getJWT()
                  )
                ).pipe(
                  map((result2) => {
                    this.notifier.show({
                      message: this.translate.instant("accountBalance.success"),
                      type: "success",
                    });
                  })
                );
              })
            );
          }
        })
      );
    result$.subscribe(() => {
        this.loadingfillAccount$.next(false);
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl("/customer-dashboard/balance");
      });
    },
        (error) =>{
            this.loadingfillAccount$.next(false);
        });
  }

  showPaymentIntentLater(clientSecret, paymentMethodId) {
    // Pass the failed PaymentIntent to your client from your server
    return from(
      stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId,
      })
    );
  }
  initiateCardElement() {
    // Giving a base style here, but most of the style is in scss file
    const cardStyle = {
      base: {
        color: "#32325d",
        iconColor: "#000000",
        backgroundColor: "rgba(211,211,211,0.15)",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "rgba(211,211,211,0.15)",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    };
    this.addCreditCardMode=true;
    this.card = elements.create("card", { cardStyle });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener("change", this.cardHandler);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes.amount) {
      console.log(changes);
    }
  }

  onChange({ error }) {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }
    this.cd.detectChanges();
  }
  createStripeToken() {
      if (this.confirming) {
          // Hier könntest du etwas tun, z.B. eine Meldung anzeigen, dass bereits ein Vorgang im Gange ist.
          return;
      }

      this.confirming = true;
      this.loading = true;

      let token$ = this.user$.pipe(
          take(1),
          switchMap((user) =>
              from(
                  stripe.confirmCardSetup(this.secret, {
                      payment_method: {
                          card: this.card,
                          billing_details: {
                              name: user.user_name + ' ' + user.user_surname,
                          },
                      },
                  }),
              ),
          ),
          finalize(() => {
              this.confirming = false;
              this.loading = false;
          }),
      );

      token$.subscribe(
          (response: any) => {
              if (response?.error) {
                  this.notifier.show({
                      type: 'error',
                      message: response.error.message,
                  });
                  this.loading = false;
                  return;
              }
              this.onSuccess(response);
              this.refreshData.next(true);
          },
          (error) => {
              this.onError(error);
          },
      );
  }
  onSuccess(token) {
    this.notifier.show({
      message: this.translate.instant("accountBalance.cc_added"),
      type: "success",
    });
  }
  onError(error) {
    if (error.message) {
      this.cardError = error.message;
      this.notifier.show({
        message: this.translate.instant("accountBalance.topup_error"),
        type: "error",
      });
    }
  }

  ngOnDestroy() {
    if (this.card) {
      // We remove event listener here to keep memory clean
      //this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
    if(this.sub){
        this.sub.unsubscribe();
    }
  }

    addBalanceToInput(amount: number) {
        this.amount= amount;
    }

    openAddCreditCard() {
        this.initiateCardElement();
    }
}

import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PhoneValidationService } from '../../services/phoneValidation/phone-validation.service';
import { LoginService } from '../login/login.service';
import { ISettingsValue } from '../admin/settings/settingsValue';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-tan-validation',
    templateUrl: './tan-validation.component.html',
    styleUrls: ['./tan-validation.component.scss'],
})
export class TanValidationComponent implements OnInit {

    code: string = '';
    loading$ = new BehaviorSubject(false);
    customerId;
    resendCodeDisabled$ = new BehaviorSubject(false);
    user;
    userPhone;
    bookingMode = false;

    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.Austria, CountryISO.Germany];

    phoneForm = new FormGroup({
        phone: new FormControl('', Validators.required),
    });


    settingPhoneCountry: ISettingsValue =
        {
            settingsKey: 'phoneCountry',
            settingsValue: '',
            settingsId: 1,
            id: 37,
        };


    airkeyError: string | null;

    constructor(private requestManager: RequestManagerService,
                private notification: NotifierService,
                private translate: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                private phoneValidationService: PhoneValidationService,
                private loginService: LoginService) {
        this.requestManager.get('/settings/settingsValue', this.requestManager.getJWT(), true)
            .then(
                (res) => {
                    let companySettings = UtilsService.getSettingsOfCategoryByName('companySettings', res);
                    this.settingPhoneCountry = UtilsService.getSettingByKeynameFromCategory('phoneCountry', companySettings);
                },
            );

    }

    ngOnInit(): void {
        this.route.url.pipe(
            /*
            At first check, if validation for booking mode
             */
            tap((url: UrlSegment[]) => {
                url.forEach((segment: UrlSegment) => {
                    if (segment.path === 'booking') {
                        this.bookingMode = true;
                    }
                });
            }),
            mergeMap((url: UrlSegment[]) => {
                /*
                if validation is not for booking mode, we have to
                get the phone number to display from url
                 */
                return this.route.queryParams.pipe(
                    tap((params) => {
                        if (!this.bookingMode) {
                            if (params['phone']) {
                                this.userPhone = '+' + params['phone'].trim();
                            }
                        }
                    }),
                );
            }),
            /*
            Get customerId from url
             */
            mergeMap((url: UrlSegment[]) => {
                return this.route.params.pipe(
                    map((params) => {
                        if (params['id'] != undefined) {
                            this.customerId = +params['id'];
                        }
                        return this.customerId;
                    }),
                    /*
                    if validation is in booking mode´,
                    get userDate from db, because we have rights to read userData
                     */
                    mergeMap((customerId) => {
                        if (this.bookingMode) {
                            return from(this.requestManager.get('/user/' + this.customerId, this.requestManager.getJWT(), true, { array_values: true }))
                                .pipe(
                                    tap((user: any) => {
                                        if (user && user.user) {
                                            this.user = Object.values(user.user).pop();
                                        }
                                    }),
                                );
                        }
                        return of();
                    }),
                );
            }),
        ).subscribe();
    }

    validCode() {
        return this.code != '';
    }

    validateCode() {
        this.loading$.next(true);
        let body = { token: this.code };
        let validateUrl = '/registration/' + this.customerId + '/verifyPhoneValidation';
        if (this.bookingMode) {
            validateUrl = '/verifyPhoneValidation/' + this.customerId;
        }
        this.requestManager.post(validateUrl, body).pipe(
            mergeMap((result) => {
                /*
                check if phone validation was succesfull
                 */
                if (result['verification'] === 'success') {
                    /*
                    in booking mode, page routes back to cashcart and starts process of airkey registration
                     */
                    if (this.bookingMode) {
                        this.notification.show({
                            message: this.translate.instant('Tan ist korrekt. Nun können Sie bei Ihrer Buchung fortfahren!'),
                            type: 'success',
                        });
                        return this.requestManager.getAuth('/airkey/registrate/' + this.customerId).pipe(
                            mergeMap((response: {
                                'EVVAPersonId': string
                                'phoneNumber': string
                            }) => {
                                return from(this.router.navigateByUrl('checkout'));
                            }),
                            catchError(error => {
                                this.loading$.next(false);

                                /*
                                 * try to get some information from errorMessage
                                 */
                                let message = null;
                                if (error.error != undefined && error.error.message != undefined) {
                                    let splittedMessage = error.error.message.toString().split('message');
                                    if (splittedMessage.length > 1) {
                                        message = splittedMessage[1].replaceAll('}', '');
                                        message = message.replaceAll('"', '');
                                        message = message.replaceAll(':', '');
                                    }
                                }

                                this.airkeyError = this.translate.instant('airkey_registration.error') + ' ' + message ?? '';

                                this.notification.show({
                                    message: this.translate.instant('airkey_registration.error') + ' ' + message ?? '',
                                    type: 'error',
                                });
                                return of(null);
                            }),
                        );
                        /*

                         */
                    } else {
                        this.notification.show({ message: this.translate.instant('registration.form.complete'), type: 'success' });
                        return from(this.router.navigateByUrl('/')).pipe(
                            tap(() => {
                                this.loginService.isLoggingIn.next(false);

                            }),
                        );
                    }
                }
                this.loading$.next(false);
                this.notification.show({ message: this.translate.instant('tan_validation.error'), type: 'error' });
                return of(null);
            }),
        )
            .subscribe((result) => {
                    this.loading$.next(false);
                },
                () => {
                    this.loading$.next(false);
                    this.notification.show({ message: this.translate.instant('tan_validation.error'), type: 'error' });
                });
    }

    resendCode() {
        this.loading$.next(true);
        return this.requestManager.post('/registration/' + this.customerId + '/initiatePhoneValidation', {}).subscribe(() => {
                this.loading$.next(false);
                this.phoneValidationService.setErrorState(null);
                this.resendCodeDisabled$.next(true);
                setTimeout(() => {
                    this.resendCodeDisabled$.next(false);
                }, 60000);
            },
            (error) => {
                console.log(error);
                this.phoneValidationService.setErrorState('500');
                this.loading$.next(false);
            });
    }

    savePhoneNumber() {
        this.loading$.next(true);
        let updateObject = {
            'phone': this.phoneForm.value['phone'].e164Number,
        };
        this.requestManager.put('/user/' + this.customerId, this.requestManager.getJWT(), updateObject)
            .pipe(
                mergeMap(() => {
                    return this.requestManager.get('/user/' + this.customerId, this.requestManager.getJWT(), true, { array_values: true });
                }),
                map((user: any) => {
                    this.user = Object.values(user.user).pop();
                })
            ).subscribe(() => {
            this.resendCode();
            this.loading$.next(false);
        });
    }

    clearPhoneNumber() {
        this.loading$.next(true);
        let updateObject = {
            'phone': null,
        };
        this.requestManager.put('/user/' + this.customerId, this.requestManager.getJWT(), updateObject)
            .pipe(
                mergeMap(() => {
                    return this.requestManager.get('/user/' + this.customerId, this.requestManager.getJWT(), true, { array_values: true });
                }),
                map((user: any) => {
                    this.user = Object.values(user.user).pop();
                }),
            ).subscribe(() => {
            this.loading$.next(false);
        });

    }

    getPhoneValidationErrorState(): Observable<string> {
        return this.phoneValidationService.getErrorState();
    }
}
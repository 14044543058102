import { Injectable } from '@angular/core';
import { isArray, omit, pick, values } from 'lodash';
import * as _ from 'lodash';

import { from, forkJoin, Observable, combineLatest } from 'rxjs';
import { map, take, mergeMap, zip } from 'rxjs/operators';
import { RequestManagerService } from '../requestManager/request-manager.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private requestManager: RequestManagerService) {}

  getBookings(date: Date) {}

  countCustomers() {
    return from(this.requestManager.getAuth('/user/count')).pipe(
        map((result) => {
            // console.log(result);
            return Number.parseInt(JSON.parse(result));
        }),
    );
  }

  getCancellationPeriods() {
    return from(this.requestManager.get('/room?select_fields=["id","cancellationPeriod"]'));
  }
  getUser(userid: number) {
    return from(this.requestManager.getAuth('/user/' + userid)).pipe(
      map((result: any) => Object.values(result.user)[0])
    );
  }

  searchRooms(name: string) {
    return from(
      this.requestManager.get(
        '/roomDescription?search_term=' +
          name
      )
    ).pipe(
      map((result) => {
       if (result.roomDescription) {
         return _.values(result.roomDescription);
       }
       return [];
      })
    );
  }

  searchBookings(name: string) {

    const where_condition = [
      {
        field: 'id',
        operator: 'LIKE',
        model: 'bookings',
        value: '%' + name + '%',
      }
    ];
    return from(
      this.requestManager.get(
        '/bookings?array_values=true&where_condition='
       + encodeURI(JSON.stringify(where_condition)),
      this.requestManager.getJWT(),
      true
      )
    ).pipe(
      map((result) => {
        if (result.bookings) {
          return _.values(result.bookings);
        }
        return [];
      })
    );
  }

  getBookingById(id: string) {

    const where_condition = [
      {
        field: 'id',
        operator: '=',
        model: 'bookings',
        value: id,
      }
    ];
      return from(
        this.requestManager.get(
          '/bookings?array_values=true&where_condition='
          + encodeURI(JSON.stringify(where_condition)),
          this.requestManager.getJWT(),
          true
        )
      ).pipe(
        map((result) => {
          if (result.bookings) {
            return _.values(result.bookings);
          }
          return [];
        })
      );
  }


  getCustomers(
    searchTerm: string = '',
    limit: number = 100,
    offset: number = 0
  ) {
    return from(
        this.requestManager.getAuth(
            '/user?limit=' +
            limit +
            '&offset=' +
            offset +
            '&search_term=' +
            searchTerm,
        ),
    ).pipe(
      map((result) => {
        const users = {};
        values(result.user).forEach((user) => {
          users[user.id] = user;
        });
        return users;
      })
    );
  }

  getRooms(locationId: number = null) {
    let whereString = '';
    if (locationId != null) {
      const whereCondition = {
        field: 'locationId',
        value: locationId,
        model: 'room',
        operator: '=',
      };
      whereString = '?where_condition=' + JSON.stringify(whereCondition);
    }

    return from(
      this.requestManager.get(
        '/room/roomDescription/roomInstruments/roomType/roomTypeOpeningHour' +
          whereString
      )
    ).pipe(
      map((result) => {
        return result.room;
      })
    );
  }
  getRoomTypes() {
    return from(
      this.requestManager.get('/roomType/roomTypeOpeningHour?array_values=true')
    ).pipe(map((result) => result.roomType));
  }
  getOpeningHours(year, month, day) {
    const date = new Date(year, month - 1, day);
    const dayOfWeek = date.getDay(); // Sunday 0
    const daysArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const dayOperator = daysArray[dayOfWeek];

    return from(
      this.requestManager.get(
        '/roomType/roomTypeOpeningHour?where_condition=' + JSON.stringify({
          model: 'roomTypeOpeningHour',
          field: 'day',
          operator: '=',
          value: '\'' + dayOperator + '\''
        })
      )
    );
  }

  payBookingWithoutCashRegister(bookingData: any) {
    if (isArray(bookingData)) {
      const result = _.map(bookingData, (booking) => booking.id);
      return this.requestManager.put('/legacy/payBookings', this.requestManager.getJWT(), result);
    }
    return this.requestManager.put('/legacy/payabooking/' + bookingData.id, this.requestManager.getJWT(), {});

  }

  createOrUpdateReceiptProducts(receiptProduct) {
    if (!receiptProduct.id) {
      return from(
        this.requestManager.post(
          '/receiptProducts',
          pick(receiptProduct, ['locationId', 'roomId', 'productId']),
          this.requestManager.getJWT()
        )
      );
    } else {
      return from(
        this.requestManager.put(
          '/receiptProducts/' + receiptProduct.id,

          this.requestManager.getJWT(),
          pick(receiptProduct, ['productId']),
        )
      );
    }
  }

  createOrUpdateLocation(location: { id: number; name: string }) {
      console.log(location);
    if (!location.id) {
      return from(
        this.requestManager.post(
          '/locations',
          pick(location, ['name', 'address', 'cashRegisterApiKey', 'cashRegisterPrinterId', 'disabled'
              , 'otherArticleId', 'paymentByCashAllowed', 'costCenter',
          ]),
          this.requestManager.getJWT()
        )
      );
    } else {
      return from(
        this.requestManager.put(
          '/locations/' + location.id,
          this.requestManager.getJWT(),
          pick(location, ['name', 'address', 'cashRegisterApiKey', 'cashRegisterPrinterId', 'disabled'
              , 'otherArticleId', 'paymentByCashAllowed', 'costCenter',
          ])
        )
      );
    }
  }

  createOrUpdateRoom(
    createRoomModels: any,
    imageFile,
    id: number = null
  ): Observable<any> {
    let myImage;
    try {
      myImage = imageFile;
    } catch (e) {
      console.log(e);
    }

    // GET POST DATA
    const roomBody = {
        price: createRoomModels.general['price'],
        entityLength: createRoomModels.general['entityLength'],
        roomTypeId: createRoomModels.general['roomtypeId'],
        locationId: createRoomModels.locationId,
        cancellationPeriod: createRoomModels.general.cancellationPeriod,
        formId: createRoomModels.general['formId'] == 'null' ? null : createRoomModels.general['formId'] == '' ? null : createRoomModels.general['formId'],
        smartphoneRequired: createRoomModels.general['smartphoneRequired'],
        airkeyLockId: createRoomModels.general['airkeyLockId'],
        airkeyAreaId: createRoomModels.general['airkeyAreaId'],
    };

    const instrumentBody = {
      instrument: createRoomModels.general['instrument'],
      roomId: 0,
    };

    const imageFormData = {
      roomId: null,
      image: myImage,
    };

    if (createRoomModels.roomData && createRoomModels.roomData.id) {
      id = createRoomModels.roomData.id;
    }

    const languages = createRoomModels.languages;
    console.log(languages);
    const descriptions = [];
    for (const language in languages) {
      if (language !== 'active' && language !== 'newLanguage') {
        const descriptionBody: {
          name;
          description;
          language;
          roomId;
          id?;
        } = {
          name: createRoomModels.languages[language].name,
          description: createRoomModels.languages[language].description,
          language: language,
          roomId: null,
        };
        if (createRoomModels.languages[language].id) {
          descriptionBody.id = createRoomModels.languages[language].id;
        }
        descriptions.push(descriptionBody);
      }
    }

    if (!id) {
      return from(this.requestManager.post('/room', roomBody)).pipe(
        map((roomIdRaw) => {
          const roomId = roomIdRaw[0];
          instrumentBody.roomId = roomId;
          imageFormData.roomId = roomId;
          const observables = [
            this.requestManager.post('/roomInstruments', instrumentBody),
          ];

          if (myImage) {
            this.requestManager.post('/roomImages', imageFormData);
          }

          for (let i = 0; i < descriptions.length; i++) {
            descriptions[i].roomId = roomId;
            observables.push(
              this.requestManager.post('/roomDescription', descriptions[i])
            );
          }

          return forkJoin(combineLatest(observables).pipe(take(1)));
        })
      );
    } else {
      let instrumentId, imageId;
      if (createRoomModels.roomData.roomInstruments) {
        instrumentId = Object.values<any>(
          createRoomModels.roomData.roomInstruments
        )[0].id;
      }

      const imageCondition = [
        {
          model: 'roomImages',
          value: id,
          field: 'roomId',
          operator: '=',
        },
      ];

      /**
       * Make all requests and exit modal on finish
       */
      return this.requestManager
        .put('/room/' + id, this.requestManager.getJWT(), roomBody)
        .pipe(
          mergeMap(() => {
            return from(
              this.requestManager.get(
                '/roomImages?where_condition=' +
                  JSON.stringify(imageCondition) +
                  '&select_fields=["id"]'
              )
            );
          }),

          mergeMap((imageResponse) => {

            let imageId = false;
            if (imageResponse.roomImages != undefined) {
              const image = Object.values<any>(imageResponse.roomImages)[0];


              if (image) {
                console.log('ImageResponse', image);
                imageId = image.id;
              }
            }

            const observables: Observable<any>[] = [];
            instrumentBody.roomId = id;
            if (instrumentId) {
              observables.push(
                this.requestManager.put(
                  '/roomInstruments/' + instrumentId,
                  this.requestManager.getJWT(),
                  instrumentBody
                )
              );
            } else {
              observables.push(
                this.requestManager.post(
                  '/roomInstruments',
                  instrumentBody,
                  this.requestManager.getJWT()
                )
              );
            }

            if (myImage) {
              if (imageId !== false) {
                observables.push(
                  this.requestManager.put(
                    '/roomImages/' + imageId,
                    this.requestManager.getJWT(),
                    pick(imageFormData, ['image'])
                  )
                );
              } else {
                imageFormData.roomId = id;
                observables.push(
                  this.requestManager.post(
                    '/roomImages',
                    imageFormData,
                    this.requestManager.getJWT()
                  )
                );
              }
            }

            for (let i = 0; i < descriptions.length; i++) {
              const descriptionBody: {
                name: string;
                description: string;
                language: string;
                roomId?: number;
              } = {
                name: descriptions[i].name,
                description: descriptions[i].description,
                language: descriptions[i].language,
              };
              if (descriptions[i].id) {
                observables.push(
                  this.requestManager.put(
                    '/roomDescription/' + descriptions[i].id,
                    this.requestManager.getJWT(),
                    descriptionBody
                  )
                );
              } else {
                descriptionBody.roomId = id;
                observables.push(
                  this.requestManager.post('/roomDescription', descriptionBody)
                );
              }
            }
            return forkJoin(combineLatest(observables).pipe(take(1)));
          })
        );

      /**
       *  Iterate through all descriptions and create description request body.
       *  Put update old descriptions and post new descriptions to database; if it is the last one close modal afterwards
       */
    }
  }
}

import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from '../../reducers';
import { catchError, filter, map, mergeMap, startWith, switchMap, take, tap } from 'rxjs/operators';
import { CartManagerService } from '../../services/cartManager/cart-manager.service';
import { Observable, from, combineLatest, BehaviorSubject, of, forkJoin, Subscription, interval } from 'rxjs';
import { RequestManagerService } from '../../services/requestManager/request-manager.service';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { DataProtectionComponent } from '../data-protection/data-protection.component';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { ModalAirkeyAppNotRegisteredComponent } from '../modal-airkey-app-not-registered/modal-airkey-app-not-registered.component';
import { UtilsService } from '../../services/utils/utils.service';
import { ISettingsValue } from '../admin/settings/settingsValue';
import { faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';


@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, OnDestroy {

    loading = false;

    $loadingPaying = new BehaviorSubject(false);

    isLoggedIn: boolean = false;
    rooms$: Observable<any>;
    saldo$: Observable<any>;
    voucherSaldo$: Observable<any>;
    totalPrice: 0;
    newVoucherSaldo: number = 0;
    voucherSaldo: number = 0;
    paidArray: any = {};
  paidByVoucher = 0;
  payInCash = 0;
  discount = 0.0;
  checkbox: {
    agb: boolean;
    storno: boolean;
      richtlinien: boolean;
      covid?: boolean;
  } = {
      agb: false,
      storno: false,
      richtlinien: false,
  };
    paying = false;
    cartRefresh$;
    requiredRoomsBookable$ = new BehaviorSubject(false);

    modalRefModalAirkeyAppNotRegisteredComponent = null;
    checkAirkeyActivatedSubscription: null | Subscription = null;
    payByCachePossible = true;

    loading$ = new BehaviorSubject(true);

    featureSettingFeaturePayingByCash: ISettingsValue =
        {
            settingsKey: 'feature_payingByCash',
            settingsValue: false,
            settingsId: 2,
            id: 22,
        };

    featureSettingFeaturepayBookingSubsequently: ISettingsValue =
        {
            settingsKey: 'feature_payBookingSubsequently',
            settingsValue: false,
            settingsId: 2,
            id: 23,
        };

    featureSettingFeaturePayBookingByCreditCard: ISettingsValue =
        {
            settingsKey: 'feature_payBookingByCreditCard',
            settingsValue: true,
            settingsId: 2,
            id: 24,
        };

    contactEmailSetting: ISettingsValue =
        {
            settingsKey: 'footer_mailto',
            settingsValue: 'infopoint@musikquartier.at',
            settingsId: 1,
            id: 25,
        };

    loadingPaymentMethods$ = new BehaviorSubject(false);
    selectedCard: any;
    card: any;
    cardHandler = this.onChange.bind(this);
    cardError: string;
    cardComplete;
    @ViewChild('cardInfo1') cardInfo1: ElementRef;
    @ViewChild('cardInfo2') cardInfo2: ElementRef;
    payment_methods$: Observable<any[]>;
    refreshData = new BehaviorSubject(false);
    faCcVisa = faCcVisa;
    faCcmasterVard = faCcMastercard;
    user;

    constructor(
        public cartManager: CartManagerService,
        private store: Store,
        private rm: RequestManagerService,
        private router: Router,
        private notifier: NotifierService,
        private translate: TranslateService,
        private matDialog: MatDialog,
        private cookieService: CookieService,
        private cd: ChangeDetectorRef,
      private modalService: MatDialog,
  ) {
        this.checkAirkeyActivated();
        this.checkIfPayingByCashIsPossible();

        /*
         * get contact email
         */
        from(this.rm.get('/settings/settingsValue', this.rm.getJWT(), true))
            .pipe(
                take(1),
                tap((res) => {
                    let companySettings = UtilsService.getSettingsOfCategoryByName('companySettings', res);
                    this.contactEmailSetting = UtilsService.getSettingByKeynameFromCategory('footer_mailto', companySettings);
                }))
            .subscribe();
    }


  ngOnInit(): void {
      this.cartManager.checkForOldBookings();
      if (this.cookieService.get("jwt")) {
          this.isLoggedIn = true;
      }

    let discount$ = this.store.pipe(
      select((state: State) => state.data.user),
    );

    let roomsState$ = this.store.pipe(
      select((state: State) => state.data.allRooms),
    );
    this.rooms$ = combineLatest([
        discount$.pipe(),
        roomsState$.pipe(filter((rooms) => Object.values(rooms).length > 0)),
    ]).pipe(
      map(([user, rooms]) => {


          this.user = user;
        if (user.discount)
          this.discount = Number.parseFloat(user.discount) / 100;
        else {
          this.discount = 0.0;
        }
        let discountFactor = 1 - this.discount;
        this.cartRefresh$ = this.cartManager.cartRefresh$.pipe(
          tap((cart) => {
            this.totalPrice = 0;

            (<any[]>cart).forEach((booking) => {
              if (rooms[booking.roomId]) {
                this.totalPrice +=
                  Number.parseFloat(rooms[booking.roomId].price) *
                  discountFactor;
              }
            });
          })
        );
        this.cartRefresh$.subscribe();

        this.totalPrice = 0;
        this.cartManager.getCart().forEach((booking) => {
          if (rooms[booking.roomId]) {
            this.totalPrice +=
              Number.parseFloat(rooms[booking.roomId].price) * discountFactor;
          }
        });


          if(this.isLoggedIn){

        let userId = user.id;

        this.saldo$ = this.cartManager.cartRefresh$.pipe(
          startWith(""),
          mergeMap(() =>
            from(
              this.rm.get(
                "/user/" + userId + "/accountsReceivable/saldo",
                this.rm.getJWT(),
                true
              )
            ).pipe(
              map((result) => {

                  return {amount: result.saldo};
              })
            )
          )
        );
        this.voucherSaldo$ = this.cartManager.cartRefresh$.pipe(
          startWith(""),
          mergeMap(() =>
            from(
              this.rm.get(
                "/user/" + userId + "/voucher/saldo",
                this.rm.getJWT(),
                true
              )
            ).pipe(
              map((result) => {
                if (
                  Array.isArray(result.accountsReceivable) &&
                  result.accountsReceivable.length == 0
                ) {
                  return { amount: 0 };
                }
                return {amount: result.saldo};
              })
            )
          )
        );

        let valuesCalculation$ = combineLatest([
          this.saldo$,
          this.voucherSaldo$,

        ]).pipe(
            map(([netSaldo, voucherSaldo]) => {

                let saldo = Number.parseFloat(netSaldo.amount);
                this.voucherSaldo = Number.parseFloat(voucherSaldo.amount);
                this.calculatePaid(rooms, voucherSaldo);
                return true;
            }),
        );

              valuesCalculation$.subscribe();
          }
          return rooms;
      }),
        tap(() => {
            this.loading$.next(false);
        }),
    );

      this.payment_methods$ = this.refreshData.pipe(
          filter((value) => value === true),
          tap(() => {
              this.loadingPaymentMethods$.next(true);
          }),
          switchMap((value) => {

              if (value) {
                  // return of(this.mockPaymentMethodsData);
                  return from(
                      this.rm.get('/stripe/availablePaymentMethods', this.rm.getJWT(), true),
                  ).pipe(
                      map((result) => {

                          if (result.data && Array.isArray(result.data) && result.data.length === 0) {
                              return false;
                          }
                          return result.data;
                      }),
                  );
              }
              return of(true);
          }),
          tap((result) => {

              if (!result) {

                  this.initiateCardElement();
              }
              this.loadingPaymentMethods$.next(false);
          }),
      );
  }

    initiateCardElement() {
        // Giving a base style here, but most of the style is in scss file
        const cardStyle = {
            base: {
                color: '#32325d',
                iconColor: '#000000',
                backgroundColor: 'rgba(211,211,211,0.15)',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: 'rgba(211,211,211,0.15)',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        };

        if (this.card === undefined) {
            this.card = elements.create('card', { cardStyle });
        }

        if (this.card !== undefined) {
            if (this.cardInfo1 !== undefined) {
                this.card.mount(this.cardInfo1.nativeElement);
            } else if (this.cardInfo2 !== undefined) {
                this.card.mount(this.cardInfo2.nativeElement);
            }
            this.card.addEventListener('change', this.cardHandler);
        }

    }

    onChange(event) {

        if (event.error) {
            this.cardError = event.error.message;
            this.cardComplete = false;
        } else {
            this.cardError = null;
            if (event.complete) {
                this.cardComplete = true;
            }
        }
        this.cd.detectChanges();
    }

    calculatePaid(rooms, voucherSaldo) {
        this.paidArray = {};
        let bookings = this.cartManager.getCart();
        let sorted = cloneDeep(bookings);
        sorted.sort((a, b) => {
            if (a.bookingTimeFrom < b.bookingTimeFrom) {
                return -1;
            } else if (a.bookingTimeFrom < b.bookingTimeFrom) {
                return +1;
      } else {
        return 0;
      }
    });


      let voucherRest = Number.parseFloat(voucherSaldo.amount);

      this.paidByVoucher = 0;
    this.payInCash = 0;
    sorted.forEach((booking) => {
      let price =
        Number.parseFloat(rooms[booking.roomId].price) * (1 - this.discount);

        if (voucherRest - price >= 0) {
        voucherRest -= price;
        this.paidByVoucher += price;
        this.paidArray[booking.bookingTimeFrom + " " + booking.roomId] = true;
      } else {
        this.payInCash += price;
      }
    });

    this.newVoucherSaldo =
      Number.parseFloat(voucherSaldo.amount) - this.paidByVoucher;

  }
  getBookingClass(booking, i) {
    return this.paidArray[booking.bookingTimeFrom + " " + booking.roomId]
      ? "paid"
      : "";
  }
  payBooking() {
      if (!this.cartManager.checkForOldBookings()) {
          this.notifier.show({
              message: this.translate.instant('checkout.error.bookings_past'),
              type: 'error',
          });
          return;
      }

      this.$loadingPaying.next(true);
      this.loading = true;

      let checkvalue = Object.values(this.checkbox).every((e) => e);
      if (!checkvalue) {
          this.notifier.show({
              message: this.translate.instant('checkout.agb_error'),
              type: 'error',
          });
          return;
      }
      this.paying = true;

      const cardForPayment: boolean = this.featureSettingFeaturePayBookingByCreditCard.settingsValue && (!!(this.selectedCard || (this.card && this.cardComplete)));

      this.cartManager.bookCart(cardForPayment).pipe(
          mergeMap((bookingsResult) => {
              if (bookingsResult === 'validation') {
                  return of(bookingsResult);
              }

              /*
             * filter successful bookings
             */
              const bookings = [];
              for (let booking of bookingsResult) {
                  if (booking.success !== undefined && booking.success == true) {
                      bookings.push(booking);
                  }
              }
              if (bookings.length > 0) {
                  if (cardForPayment === true) {
                      if (this.selectedCard) {
                          return this.handleBookingPaymentByCard(bookings, bookingsResult, this.selectedCard.id);
                      } else if (this.card && this.cardComplete) {
                          return from(
                              /*
                               * create/get stripe setupIntent
                               */
                              this.rm.getAuth('/stripe/setupIntent', this.rm.getJWT()),
                          ).pipe(
                              mergeMap((setupIntentResult) => {

                                  /*
                                   * confirm setupIntent with card
                                   */
                                  return from(
                                      stripe.confirmCardSetup(setupIntentResult.client_secret, {
                                          payment_method: {
                                              card: this.card,
                                              billing_details: {
                                                  name: this.user.name + ' ' + this.user.surname,
                                              },
                                          },
                                      }),
                                  ).pipe(
                                      mergeMap((setupResult) => {
                                          return this.handleBookingPaymentByCard(bookings, bookingsResult);
                                      }),
                                  );
                              }),
                          );
                      }
                      this.notifier.show({
                          message: this.translate.instant('ERROR_NO_CREDITCARD'),
                          type: 'error',
                      });
                      return of('No card available!');
                  } else {
                      return of(bookingsResult);
                  }
              }
              this.notifier.show({
                  message: this.translate.instant('ERROR_ALL_BOOKINGS_FAILED'),
                  type: 'error',
              });
              return of('No successful booking!');
          }),
          tap((result) => {

              if (result === 'validation') {
                  this.notifier.show({
                      message: this.translate.instant('WARNING_PHONE_VALIDATION'),
                      type: 'warning',
                  });
              } else {
                  this.notifier.show({
                      message: this.translate.instant('checkout.success'),
                      type: 'success',
                  });
              }

          }),
      )
          .subscribe((result) => {
                  if (cardForPayment && result !== 'validation') {
                      this.cartManager.restartCart();
                  }
                  this.paying = false;
                  this.$loadingPaying.next(false);

                  if (result !== 'validation') {
                      this.router.navigateByUrl('customer-dashboard/bookings', {state: {isBooked: true}});
                  }
              },
              (error) => {
                  console.log(error);
                  //http code 402 Noch unbezahlte Buchungen in der Vergangenheit
                  if (error.status == 402) {
                      this.notifier.show({
                          type: 'error',
                          message:
                              this.translate.instant('checkout.open_bookings'),
                      });
                  } else if (error.error == 'System Error:There are unpaid bookings in the past') {
                      this.notifier.show({
                          type: 'error',
                          message:
                              this.translate.instant('checkout.open_bookings'),
                      });

                  } else {
                      this.notifier.show({
                          type: 'error',
                          message:
                              this.translate.instant('booking.error'),
                      });
                  }
                  this.$loadingPaying.next(false);
                  this.paying = false;
              });

  }

  btnAcceptAll(){
    this.checkbox.agb= true;
    this.checkbox.richtlinien = true;
    this.checkbox.storno = true;
  }

  openDataProtection() {
      this.matDialog.open(DataProtectionComponent);
  }

    deleteAllBookingsFromCard() {
       this.cartManager.restartCart();
    }

    public openLogin() {
        localStorage.setItem('isLogging','true');
        this.router.navigateByUrl('login');
    }

    openRegistration() {
        this.router.navigateByUrl('register');
    }

    navigateToBook() {
        this.router.navigateByUrl('/');
    }

    checkIfAllAccepted() {
        if (this.checkbox.storno == false
            || this.checkbox.agb == false
            || this.checkbox.richtlinien == false) {
            return false;
        }
        return true;
    }

    private checkIfPayingByCashIsPossible() {
        if (this.cookieService.get('jwt')) {
            this.rm.getAuth('/settings/settingsValue', this.rm.getJWT())
                .pipe(
                    take(1),
                    tap((res) => {
                        let featureSettings = UtilsService.getSettingsOfCategoryByName('featureSettings', res);
                        this.featureSettingFeaturePayingByCash = UtilsService.getSettingByKeynameFromCategory('feature_payingByCash', featureSettings);
                        this.featureSettingFeaturePayingByCash.settingsValue = this.featureSettingFeaturePayingByCash.settingsValue === 'true';
                        this.featureSettingFeaturepayBookingSubsequently = UtilsService.getSettingByKeynameFromCategory('feature_payBookingSubsequently', featureSettings);
                        this.featureSettingFeaturepayBookingSubsequently.settingsValue = this.featureSettingFeaturepayBookingSubsequently.settingsValue === 'true';
                        this.featureSettingFeaturePayBookingByCreditCard = UtilsService.getSettingByKeynameFromCategory('feature_payBookingByCreditCard', featureSettings);
                        this.featureSettingFeaturePayBookingByCreditCard.settingsValue = this.featureSettingFeaturePayBookingByCreditCard.settingsValue === 'true';

                    }),
                    switchMap(() => {

                        if (this.featureSettingFeaturePayingByCash.settingsValue) {

                            const requests = [];
                            this.cartManager.getCart().forEach((booking) => {
                                requests.push(this.rm.getAuth('/room/' + booking.roomId + '/locations?array_values=true', this.rm.getJWT())
                                    .pipe(map((room) => {
                                        if (Object.values(room.room)[0]) {
                                            room = Object.values(room.room)[0];
                                            if (Object.values(room.locations).length === 1) {
                                                const location: any = Object.values(room.locations)[0];

                                                    if (location.paymentByCashAllowed != '1') {
                                                        this.payByCachePossible = false;
                                                    }
                                            }
                                        }
                                            return room;
                                        }),
                                    ),
                                );
                            });
                            return forkJoin(requests);
                        } else {

                            this.payByCachePossible = false;
                        }
                        return of([]);
                    }),
                ).subscribe((result) => {
                if (this.featureSettingFeaturePayBookingByCreditCard.settingsValue) {
                    this.refreshData.next(true);
                }
            });

        }

    }

    selectMethod(pm) {
        this.selectedCard = pm;
    }

    bookingIsAllowed() {
        /*
         * booking is not allowed if payingSubsequently,payingByCash, payingByCreditCard are deactivated
         */


        if (!this.featureSettingFeaturepayBookingSubsequently.settingsValue
            && !this.featureSettingFeaturePayingByCash.settingsValue
            && !this.featureSettingFeaturePayBookingByCreditCard.settingsValue) {

            if (this.totalPrice > this.voucherSaldo) {
                return false;
            }
        }


        /*
        * booking is not allowed if payingSubsequently and payingByCash are deactivated, payingByCreditCard is activated but no creditCard is selected
        */

        if (this.featureSettingFeaturePayBookingByCreditCard.settingsValue &&
            !this.featureSettingFeaturepayBookingSubsequently.settingsValue
            && !this.featureSettingFeaturePayingByCash.settingsValue) {

            /*
             if voucherSaldo is greater equal price of bookings, pay by voucher is allowed
             */
            if (this.voucherSaldo >= this.totalPrice) {
                return true;
            }

            if (!this.selectedCard
                && !this.cardComplete) {
                return false;
            }
        }

        return true;
    }

    private checkAirkeyActivated() {
        const checkRoomsAndUser$ = this.cartManager.getRooms()
            .pipe(
                switchMap((rooms) => {

                    let bookings = cloneDeep<any>(this.cartManager.getCart());
                    let smartphoneRequiredRoom = false;
                    /*
                    check if there is a booking with a smartphone required room
                     */
                    if (Object.keys(rooms).length > 0) {
                        bookings.forEach((booking) => {
                            if (rooms[booking.roomId].smartphoneRequired === '1') {
                                smartphoneRequiredRoom = true;
                            }
                        });
                    } else {
                        return of({ roomsBookable: false, openModal: false });
                    }


                    if (smartphoneRequiredRoom) {
                        const userId = this.rm.getIdOfUserThatIsLoggedIn();

                        if (userId !== null) {
                            return this.rm.getAuth('/airkey/person/' + userId + '/status').pipe(
                                map((statusResult) => {

                                    /*
                                     * if phone is not activated,
                                     * or there is no app data availaible,
                                     * or pairingCode is not used
                                     * or no phone is registrated to user personId;
                                     *      disable booking and show modal for airkey registration
                                     */
                                    return {
                                        roomsBookable: !(statusResult['activated'] !== undefined && (statusResult['activated'] === false || statusResult['activated'] === 'NO_PHONE')),
                                        openModal: true,
                                    };
                                }),
                            );
                        }
                        return of({ roomsBookable: false, openModal: true });
                    }
                    return of({ roomsBookable: true, openModal: false });
                }),
                catchError((error) => {
                    return of({ roomsBookable: false, openModal: false });
                }),
                tap((result: { roomsBookable: boolean; openModal: boolean }) => {
                    if (result.roomsBookable) {
                        this.requiredRoomsBookable$.next(true);
                    } else {
                        this.requiredRoomsBookable$.next(false);
                        if (result.openModal) {

                            if (this.modalRefModalAirkeyAppNotRegisteredComponent === null) {
                                this.modalRefModalAirkeyAppNotRegisteredComponent = this.modalService.open(ModalAirkeyAppNotRegisteredComponent);
                                this.modalRefModalAirkeyAppNotRegisteredComponent.afterClosed().subscribe(() => {
                                    this.modalRefModalAirkeyAppNotRegisteredComponent = null;
                                });
                            }
                        }
                    }
                }),
            );

        /*
         * execute subscription initially one time and then all 10 seconds
         */
        checkRoomsAndUser$.pipe(
            take(1),
        ).subscribe(() => {
            },
            () => {
                this.notifier.show({
                    type: 'error',
                    message:
                        this.translate.instant('ERROR_LOADING_AIRKEY_STATUS').replace('EMAIL_PLACEHOLDER', this.contactEmailSetting.settingsValue),
                });
            });

        this.checkAirkeyActivatedSubscription = interval(5000).pipe(
            switchMap(() => {
                return checkRoomsAndUser$;
            }),
        ).subscribe(() => {
            },
            () => {
                this.notifier.show({
                    type: 'error',
                    message:
                        this.translate.instant('ERROR_LOADING_AIRKEY_STATUS').replace('EMAIL_PLACEHOLDER', this.contactEmailSetting.settingsValue),
                });
            });


    }

    ngOnDestroy() {
        if (this.card) {
            // We remove event listener here to keep memory clean
            //this.card.removeEventListener('change', this.cardHandler);
            this.card.destroy();
        }
        if (this.checkAirkeyActivatedSubscription !== null) {
            this.checkAirkeyActivatedSubscription.unsubscribe();
        }
    }

    unselectCard() {
        this.selectedCard = null;
    }

    handleBookingPaymentByCard(bookings: any, bookingsResult: any, selectedCardId: string | null = null): Observable<any> {
        /*
         * create paymentIntent
         */
        return this.rm.post('/stripe/paymentIntent' + (selectedCardId !== null ? ('?paymentMethod=' + selectedCardId) : ''), bookings)
            .pipe(
                mergeMap((paymentIntentResult) => {
                    let confirmPaymentBody = {};
                    if (selectedCardId === null) {
                        confirmPaymentBody = {
                            payment_method: {
                                card: this.card,
                                billing_details: {
                                    name: this.user.name + ' ' + this.user.surname,
                                    email: this.user.email,
                                    address: {
                                        city: this.user.city ?? ' ',
                                        line1: this.user.street ?? ' ',
                                        postal_code: this.user.zip ?? ' ',
                                    },
                                },
                            },
                        };
                    }

                    /*
                     * confirm Payment with card
                     */
                    return from(stripe.confirmCardPayment(
                        paymentIntentResult.client_secret,
                        confirmPaymentBody,
                    )).pipe(
                        mergeMap((confirmPaymentResult: any) => {
                            if (confirmPaymentResult.paymentIntent !== undefined
                                && confirmPaymentResult.paymentIntent.status !== undefined
                                && confirmPaymentResult.paymentIntent.status === 'succeeded') {
                                return this.rm.post('/bookings/confirmPayment?type=card', bookings).pipe(
                                    map((resultConfirmBookings) => {
                                        return bookingsResult;
                                    }),
                                );
                            }
                            this.cartManager.restartCart();
                            this.notifier.show({
                                message: 'Es gab ein Problem bei der Bezahlung!',
                                type: 'error',
                            });
                            return of('Payment failed!');
                        }),
                    );

                }),
            );

    }
}

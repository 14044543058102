import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RequestManagerService } from '../../../../services/requestManager/request-manager.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { EditUserBaseDataModalComponent } from './edit-user-base-data-modal/edit-user-base-data-modal.component';
import { EditUserAddressDataModalComponent } from './edit-user-address-data-modal/edit-user-address-data-modal.component';
import { ChangeUserRightsConfirmModalComponent } from './change-user-rights-confirm-modal/change-user-rights-confirm-modal.component';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { EditSettingsModalComponent } from './edit-settings-modal/edit-settings-modal.component';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { changeLanguage } from '../../../../actions/ui.actions';
import { Store } from '@ngrx/store';


@Component({
    selector: 'app-customer-dashboard-data',
    templateUrl: './customer-dashboard-data.component.html',
    styleUrls: ['./customer-dashboard-data.component.scss'],
})
export class CustomerDashboardDataComponent implements OnInit {

    public loading = true;
    protected editMode = false;
    public editedUserObject;
    adminMode = 0;
    officeMode = 0;
    editRightMode = 0;
    public user;

    userIsAdmin;
    userIsOffice;
    sub;

    activeModal;
    modalTitle;
    modalBodyText;

    private updateObject: any = {};

    protected originalData;
    @Input()
    customerId;

    @Output()
    loadingDone = new EventEmitter<string>();

    constructor(private _requestManager: RequestManagerService,
                private route: ActivatedRoute,
                private matDialogService: MatDialog,
                private notifier: NotifierService,
                private translate: TranslateService,
                private store: Store) {
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            if (params['id'] != undefined) {
                this.customerId = +params['id'];
            }
        });

        this.loadUserData();
        this.userIsAdmin = this._requestManager.getUserIsAdmin();
        this.userIsOffice = this._requestManager.getUserIsOffice();

    }

    disableLoading() {
        this.loading = false;
        this.loadingDone.next('data');
    }


    disableEditMode() {
        this.editMode = false;
        this.editedUserObject = {};
    }

    loadUserData(forceRequest = false) {

        this._requestManager.get('/user/' + this.customerId, this._requestManager.getJWT(), forceRequest).then(userdata => {
            Object.keys(userdata['user']).forEach(key => {
                this.user = userdata['user'][key];
            });
            this.disableEditMode();
            this.disableLoading();
        }, () => {
        });
    }

    setUserRightsModal( role, mode) {

      mode = mode ? 1 : 0;

      this.updateObject = {};
      for (const key in this.editedUserObject) {
        this.updateObject[key] = this.editedUserObject[key];
      }

      switch (role) {
        case 'admin':
          this.updateObject.isAdmin = mode;
            this.modalTitle = 'Administrator Rechte';
            if (mode === 1) {
              this.modalBodyText = 'Möchten Sie den Benutzer ' + this.user.name + ' '
                +  this.user.surname +  ' wirklich zum Administrator machen?';
              this.adminMode= 1;
            } else {
              this.modalBodyText = 'Möchten Sie dem Benutzer ' + this.user.name + ' '
                +  this.user.surname +  ' wirklich die Administrator-Rechte entziehen?';
                this.adminMode= 2;
            }
            this.editRightMode = 1;
          break;
        case 'office':
          this.updateObject.isOffice = mode;
            this.modalTitle = 'Office Rechte';
          if (mode === 1) {
            this.modalBodyText = 'Möchten Sie dem Benutzer ' + this.user.name + ' '
              +  this.user.surname +  ' wirklich Office-Rechte geben?';
            this.officeMode= 1;
          } else {
            this.modalBodyText = 'Möchten Sie dem Benutzer ' + this.user.name + ' '
              +  this.user.surname +  ' wirklich die Office-Rechte entziehen?';
            this.officeMode= 2;
          }
          this.editRightMode = 1;
          break;
        case 'disable':
          this.updateObject.deleted = mode;
          if (mode === 1) {
            this.modalTitle = 'Benutzer Deaktivierung';
            this.modalBodyText = 'Möchten Sie den Account von Benutzer ' + this.user.name + ' '
              +  this.user.surname +  ' wirklich deaktivieren?';
          } else {
            this.modalTitle = 'Benutzer Aktivierung';
            this.modalBodyText = 'Möchten Sie den Account von Benutzer ' + this.user.name + ' '
              +  this.user.surname +  ' wirklich aktivieren?';
          }
          break;

        case 'delete':

          this.updateObject.title = null;
          this.updateObject.street = null;
          this.updateObject.zip = null;
          this.updateObject.city = null;
          this.updateObject.country = null;
            this.updateObject.dateOfBirth = null;
            this.updateObject.email = null;
            this.updateObject.phone = null;
            this.updateObject.searchTerms = null;
            this.updateObject.userNote = null;
            this.updateObject.deleted = 1;
            this.updateObject.anonymized = 1;
            this.updateObject.name = 'deleted';
            this.updateObject.surname = 'anonymized';
            this.updateObject.lastToken = null;
            this.updateObject.language = null;
            this.updateObject.username = null;
            this.updateObject.sendBookingConfirmationEmails = null;
            if (mode === 1) {
                this.modalTitle = 'Benutzer löschen';
                this.modalBodyText = 'Möchten Sie den Account dieses Benutzers wirklich gemäß DSGVO unwiderruflich löschen?';
            }
            break;
      }

      const modal= this.matDialogService.open(ChangeUserRightsConfirmModalComponent,
          {
              data: {
                  modalTitle: this.modalTitle,
                  modalBodyText: this.modalBodyText
              }
          });

      modal.afterClosed().subscribe((response) => {
          if(response == 'save'){
              this.updateUserDataRequest(this.updateObject, false, role);
          }
      })

    }

    updateUserDataRequest(updateObject, disablEditMode = false, mode = 'update') {

        this._requestManager.put('/user/' + this.customerId, this._requestManager.getJWT(), updateObject).pipe(
            mergeMap(() => {
                if (mode === 'delete') {
                    return this._requestManager.getAuth('/airkey/person/' + this.customerId + '/delete');
                }
                return of(true);
            }),
            catchError(error => {
                this.notifier.show({
                    type: 'error',
                    message: 'Fehler beim Löschen des Airkey - Accounts!',
                });
                return of();
            }),
        )
            .subscribe(() => {
                    if (this.editRightMode) {
                        this.updateAclGroup(disablEditMode);
                    } else {
                        this.notifier.show({
                            type: 'success',
                            message: this.translate.instant('CHANGES_SAVED'),
                        });
              this.loadUserData(true);
              if (disablEditMode) {
                  this.disableEditMode();
              }
          }
        this.editRightMode=0;
      },
          () => {
              this.notifier.show({
                  type: 'error',
                  message: this.translate.instant('CHANGES_NOT_SAVED'),
              });
          });
  }


    private updateAclGroup(disablEditMode) {
        let request= null;
        if(this.adminMode === 1){
            request= this._requestManager.put('/acl/user',this._requestManager.getJWT(),{
                userId: this.user.id,
                groupId: 4
            });
            this.adminMode =0;
        } else if (this.adminMode === 2){
            request= this._requestManager.deleteWithBody('/acl/user',this._requestManager.getJWT(),{
                userId: this.user.id,
                groupId: 4
            });
            this.adminMode =0;
        } else if(this.officeMode === 1){
            request= this._requestManager.put('/acl/user',this._requestManager.getJWT(),{
                userId: this.user.id,
                groupId: 3
            });
            this.officeMode= 0;
        } else if (this.officeMode === 2 ){
            request= this._requestManager.deleteWithBody('/acl/user',this._requestManager.getJWT(),{
                userId: this.user.id,
                groupId: 3
            });
            this.officeMode= 0;
        }

        if(request){
            request.subscribe(() => {
                this.notifier.show({
                    type: "success",
                    message: this.translate.instant('CHANGES_SAVED'),
                });
                this.loadUserData(true);
                if (disablEditMode) {
                    this.disableEditMode();
                }
            },
                () => {
                    this.notifier.show({
                        type: 'error',
                        message: this.translate.instant('CHANGES_NOT_SAVED'),
                    });
                })
        }
    }

    openEditBaseData() {
        const modal = this.matDialogService.open(EditUserBaseDataModalComponent,
            {data:this.user}
        );

        modal.afterClosed().subscribe((response) =>{
            if(response != 'cancel') {
                this.updateUser(response);
            }
        })
    }

    openEditAdressData() {
        const modal = this.matDialogService.open(EditUserAddressDataModalComponent,
            {data:this.user}
        );

        modal.afterClosed().subscribe((response) => {
            if (response != 'cancel') {
                this.updateUser(response);
            }

        });
    }

    userPrintChanged(deactivatePrinting: string) {
        this.user.deactivatePrinting = deactivatePrinting == '1' ? 0 : 1;
        this.updateUser(this.user);
    }

    userSendBookingConfirmationEmails(sendBookingConfirmationEmails: string) {
        this.user.sendBookingConfirmationEmails = sendBookingConfirmationEmails != '0' ? 0 : 1;
        this.updateUser(this.user);
    }


    userLanguageChanged(language: string) {
        this.user.language = language;
        this.translate.use(language.toLowerCase());
        this.store.dispatch(changeLanguage({ language: language }));
        this.updateUser(this.user);
    }

    updateUser(change) {
        for (const key in this.editedUserObject) {
            this.updateObject[key] = this.editedUserObject[key];
        }

        for (const key in this.user) {
            if (this.user[key] != change[key]) {
                this.user[key] = change[key];
            }
        }

        if (this.userIsAdmin == '1') {
            this.editedUserObject = {
                'name': this.user.name,
                'surname': this.user.surname,
                'street': this.user.street,
                'zip': this.user.zip,
                'city': this.user.city,
                'country': this.user.country,
                'phone': this.user.phone,
                'userNote': this.user.userNote,
                'discount': this.user.discount,
                'deactivatePrinting': this.user.deactivatePrinting,
                'termOfCredit': this.user.termOfCredit,
                'language': this.user.language,
                'sendBookingConfirmationEmails': this.user.sendBookingConfirmationEmails,
                'taxFree': this.user.taxFree,
            };
        } else if (this.userIsOffice == '1') {
            this.editedUserObject = {
                'name': this.user.name,
                'surname': this.user.surname,
                'street': this.user.street,
                'zip': this.user.zip,
                'city': this.user.city,
                'country': this.user.country,
                'phone': this.user.phone,
                'userNote': this.user.userNote,
                'deactivatePrinting': this.user.deactivatePrinting,
                'termOfCredit': this.user.termOfCredit,
                'language': this.user.language,
                'sendBookingConfirmationEmails': this.user.sendBookingConfirmationEmails,
                'taxFree': this.user.taxFree,
            };
        } else {
            this.editedUserObject = {
                'name': this.user.name,
                'surname': this.user.surname,
                'street': this.user.street,
                'zip': this.user.zip,
                'city': this.user.city,
                'country': this.user.country,
                'phone': this.user.phone,
                'deactivatePrinting': this.user.deactivatePrinting,
                'termOfCredit': this.user.termOfCredit,
                'language': this.user.language,
                'sendBookingConfirmationEmails': this.user.sendBookingConfirmationEmails,
                'taxFree': this.user.taxFree,
            };
        }


        this.updateUserDataRequest(this.editedUserObject);
    }

    openPasswordChangeModal() {
        this.matDialogService.open(ChangePasswordModalComponent,
            {
                data: {
                    customerId: this.customerId,
                },
            },
        );
    }

    openEditSettings() {
        const modal = this.matDialogService.open(EditSettingsModalComponent,
            { data: this.user },
        );
        modal.afterClosed().subscribe((response) => {
            if (response != 'cancel') {
                this.updateUser(response);
            }

        });
    }


}
